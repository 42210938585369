import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

export default function Template({ data }) {
  return (
    <Layout>
        <div className="container my-5">
          <div
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          />
        </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
